<template>
    <ui-row large spaceEvenly stackMobile alignTop padding>
        <ui-col offsetSmall flexStart half>
            <ui-h color="black"> Already got an account </ui-h>

            <div class="danger" v-if="showLoginError">
                <ui-p center color="#ff0000">{{ loginErrorMessage }}</ui-p>
            </div>

            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onLogin)">
                    <div class="form-wrapper">
                        <label>Email Address</label>
                        <ValidationProvider
                            name="Email Address"
                            rules="required|email"
                            v-slot="{ errors }"
                        >
                            <input
                                class="form-input"
                                v-model="loginEmail"
                                type="text"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="form-wrapper">
                        <label>Password</label>
                        <ValidationProvider
                            name="Password"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <input
                                class="form-input"
                                v-model="loginPassword"
                                type="password"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <ui-p>
                        <ui-button primary fullMobile :loading="isLoggingIn">
                            Login
                        </ui-button>
                    </ui-p>
                </form>
            </ValidationObserver>

            <ui-p>
                <ui-button
                    v-on:tapped="$modal.show('reset-password')"
                    secondary
                    fullMobile
                >
                    Reset my password
                </ui-button>
            </ui-p>

            <modal height="auto" scrollable adaptive name="reset-password">
                <div class="modal">
                    <ui-h small black>Reset my password</ui-h>
                    <ui-p black
                        >Enter your email address below to begin resetting your
                        password.</ui-p
                    >

                    <ValidationObserver v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(onReset)">
                            <div class="form-wrapper">
                                <label>Email Address</label>
                                <ValidationProvider
                                    name="Email Address"
                                    rules="required|email"
                                    v-slot="{ errors }"
                                >
                                    <input
                                        class="form-input"
                                        v-model="resetEmail"
                                        type="text"
                                    />
                                    <span class="form-error">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>

                            <ui-p>
                                <ui-button primary :loading="isResetting"
                                    >Reset now</ui-button
                                >
                            </ui-p>
                        </form>
                    </ValidationObserver>
                </div>
            </modal>
        </ui-col>

        <ui-col offsetSmall flexStart half borderRight>
            <ui-h color="black"> Register a new account </ui-h>

            <ui-p color="black">
                Your password must be at least 8 characters and one uppercase
                character and one number/special character.
            </ui-p>
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onRegister)">
                    <div class="form-wrapper">
                        <label>Email Address</label>
                        <ValidationProvider
                            name="Email Address"
                            rules="required|email"
                            v-slot="{ errors }"
                        >
                            <input
                                class="form-input"
                                type="text"
                                v-model="registerEmail"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="form-wrapper">
                        <label>Password</label>
                        <ValidationProvider
                            name="Password"
                            vid="password"
                            :rules="{
                                required: true,
                                regex: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                            }"
                            v-slot="{ errors }"
                        >
                            <input
                                class="form-input"
                                type="password"
                                v-model="registerPassword"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="form-wrapper">
                        <label>Confirm Password</label>
                        <ValidationProvider
                            name="Confirm Password"
                            rules="required|confirmed:password"
                            v-slot="{ errors }"
                        >
                            <input
                                class="form-input"
                                type="password"
                                v-model="registerConfirmPassword"
                            />
                            <span class="form-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <ui-p>
                        <ui-button fullMobile primary :loading="isRegistering"
                            >Create account
                        </ui-button>
                    </ui-p>
                </form>
            </ValidationObserver>

            <modal height="auto" scrollable adaptive name="register">
                <div class="modal">
                    <ui-h color="black"> Registration complete </ui-h>

                    <ui-p color="black">
                        We've sent an email to {{ this.registerEmail }} with a
                        link that you will need to click/tap to verifiy your
                        account. Once you have done that you can then login to
                        your account.
                    </ui-p>

                    <ui-p color="black">
                        NOTE: If you don't see the email please check your
                        junk/spam folder.
                    </ui-p>

                    <ui-p>
                        <ui-button
                            secondary
                            v-on:tapped="$modal.hide('register')"
                            >Close</ui-button
                        >
                    </ui-p>
                </div>
            </modal>

            <modal height="auto" scrollable adaptive name="verify-email">
                <div class="modal">
                    <ui-h color="black">
                        Your account has not been verified
                    </ui-h>

                    <ui-p color="black">
                        When you registered an account you would have received
                        an email to verify your account. If you need to verify
                        your account simply close this window and reset your
                        password.
                    </ui-p>

                    <ui-p>
                        <ui-button
                            secondary
                            v-on:tapped="$modal.hide('verify-email')"
                            >Close</ui-button
                        >
                    </ui-p>
                </div>
            </modal>
        </ui-col>
    </ui-row>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import firebase from "firebase/app";
import "firebase/auth";

extend("required", required);
extend("email", email);
extend("regex", regex);
extend("confirmed", {
    ...confirmed,
    message: "Passwords do not match",
});

export default {
    data() {
        return {
            loginEmail: "",
            loginPassword: "",
            isLoggingIn: false,
            showLoginError: false,
            loginErrorMessage: "",

            registerEmail: "",
            registerPassword: "",
            registerConfirmPassword: "",
            isRegistering: false,
            showRegisterError: false,
            registerErrorMessage: "",

            resetEmail: "",
            isResetting: false,
        };
    },

    components: {
        ValidationObserver,
    },

    methods: {
        onReset() {
            console.log(this.resetEmail);

            firebase
                .auth()
                .sendPasswordResetEmail(this.resetEmail)
                .then(() => {
                    this.$modal.hide("reset-password");
                    this.$toasted.show(
                        `Reset password sent to ` + this.resetEmail,
                        {
                            position: "bottom-center",
                            theme: "bubble",
                        }
                    );
                })
                .catch((error) => {
                    console.log(error.message);
                    this.$toasted.show(
                        "No account with this email address exists",
                        {
                            position: "bottom-center",
                            theme: "bubble",
                        }
                    );
                });
        },

        onLogin() {
            this.isLoggingIn = true;

            firebase
                .auth()
                .signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
                .then((userCredential) => {
                    var user = userCredential.user;

                    if (!user.emailVerified) {
                        this.$modal.show("verify-email");
                        return;
                    }
                    this.$store.commit("mutationResetUserState");
                    this.$store.commit("mutationAuthenticatedStatus", true);

                    this.$store.dispatch("actionGetUserSubscription", {
                            uid: user.uid,
                            product:
                                process.env.VUE_APP_STRIPE_MONTH_SUB_PRICE_KEY,
                        })
                        .then((subs) => {
							subs.length 
								? this.$store.commit("mutationFilterSubscriptions",subs)
								: null
                            
                        })
                        .catch((err) => {
                            console.log(err);
                            this.$toasted.show("Failed getting subscription", {
                                position: "bottom-center",
                                theme: "bubble",
                            });
                        })
                        .finally(() => {
                            this.$store.commit("mutationLoadingSub", false);
                        });

                    this.$store.commit("mutationStoreUser", {
                        uid: user.uid,
                        email: user.email,
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        emailVerified: user.emailVerified,
                    });

                    this.buildUserProfile()
                })
                .catch((error) => {
                    console.log(error)
                    this.showLoginError = true;
                    this.loginErrorMessage =
                        "There is no account associated with this email address and password combination.";
                })
                .finally(() => {
                    this.isLoggingIn = false;
                });
        },

        buildUserProfile() {

			this.$store.commit('mutationIsLoadingDogs', true)
			this.$store.commit('mutationIsLoadingGroups', true)
			this.$store.commit('mutationIsLoadingCalendarEvents', true)

			/** 
			 * Loading Dogs
			 */
			const getDogs = this.$store.dispatch('actionStoreUserDogs', this.$store.state.user.auth.uid)
			.then((dogs) => {
				console.log('Successfully loaded dogs info')
				this.$store.commit('mutationGetDogs', {
					dogs,
					uid: this.$store.state.user.auth.uid
				})
				
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.$store.commit('mutationIsLoadingDogs', false)
			})

			/** 
			 * Loading Groups
			 */
			const getGroups = this.$store.dispatch('actionStoreUserGroups', this.$store.state.user.auth.uid)
			.then((groups) => {
				console.log('Successfully loaded walks info')
				this.$store.commit('mutationGetGroups', groups)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.$store.commit('mutationIsLoadingGroups', false)
			})

			/**
			 * Loading events
			 */
			const getEvents = this.$store
			.dispatch("actionGetEventsForCurrentYear", {
				uid: this.$store.state.user.auth.uid,
				year: new Date().getFullYear(),
			})
			.then((res) => {
				this.$store.commit('mutationStoreEvents', res)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				this.$store.commit('mutationIsLoadingCalendarEvents', false)
			})

			return Promise.all([getDogs, getGroups, getEvents])
			.then(() => {
                this.$router.push("/account/details");
			})
			.catch((err) => {
				console.log(err)
			})
		},

        async onRegister() {
            this.isRegistering = true;

            await firebase
                .auth()
                .createUserWithEmailAndPassword(
                    this.registerEmail,
                    this.registerPassword
                )
                .then((userCredential) => {
                    this.sendVerificationEmail(userCredential);
                })
                .catch((error) => {
                    console.log(error);
                    this.$toasted.show(
                        "An account with " +
                            this.registerEmail +
                            " already exists",
                        {
                            position: "bottom-center",
                            theme: "bubble",
                        }
                    );
                })
                .finally(() => {
                    this.isRegistering = false;
                });
        },

        async sendVerificationEmail(userCredential) {
            await userCredential.user.sendEmailVerification().then(
                () => {
                    let user = userCredential.user;
                    this.$store.commit(
                        "mutationStoreUser",
                        JSON.parse(JSON.stringify(user))
                    );
                    this.$modal.show("register");
                },
                (error) => {
                    console.log("Error sending email verification: " + error);
                }
            );
        },
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: "futura";
    src: url("~@/assets/fonts/futuram.woff");
}

.form-wrapper {
    & + & {
        margin-top: rem(20);
    }
}

.form-input {
    background: $white;
    border: 1px solid #aaaaaa;
    border-bottom: 2px solid $primary;
    font-size: 18px;
    padding: rem(15);
    width: 100%;
    box-sizing: border-box;
}

.form-error {
    color: $error;
    font-family: "futura";
}

.danger {
    margin-bottom: rem(20);
    display: block;
    border: 2px solid $error;
    padding: 0 rem(20);
}
</style>