var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-row',{attrs:{"large":"","spaceEvenly":"","stackMobile":"","alignTop":"","padding":""}},[_c('ui-col',{attrs:{"offsetSmall":"","flexStart":"","half":""}},[_c('ui-h',{attrs:{"color":"black"}},[_vm._v(" Already got an account ")]),(_vm.showLoginError)?_c('div',{staticClass:"danger"},[_c('ui-p',{attrs:{"center":"","color":"#ff0000"}},[_vm._v(_vm._s(_vm.loginErrorMessage))])],1):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onLogin)}}},[_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Email Address")]),_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginEmail),expression:"loginEmail"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.loginEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.loginEmail=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginPassword),expression:"loginPassword"}],staticClass:"form-input",attrs:{"type":"password"},domProps:{"value":(_vm.loginPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.loginPassword=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ui-p',[_c('ui-button',{attrs:{"primary":"","fullMobile":"","loading":_vm.isLoggingIn}},[_vm._v(" Login ")])],1)],1)]}}])}),_c('ui-p',[_c('ui-button',{attrs:{"secondary":"","fullMobile":""},on:{"tapped":function($event){return _vm.$modal.show('reset-password')}}},[_vm._v(" Reset my password ")])],1),_c('modal',{attrs:{"height":"auto","scrollable":"","adaptive":"","name":"reset-password"}},[_c('div',{staticClass:"modal"},[_c('ui-h',{attrs:{"small":"","black":""}},[_vm._v("Reset my password")]),_c('ui-p',{attrs:{"black":""}},[_vm._v("Enter your email address below to begin resetting your password.")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onReset)}}},[_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Email Address")]),_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.resetEmail),expression:"resetEmail"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.resetEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.resetEmail=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ui-p',[_c('ui-button',{attrs:{"primary":"","loading":_vm.isResetting}},[_vm._v("Reset now")])],1)],1)]}}])})],1)])],1),_c('ui-col',{attrs:{"offsetSmall":"","flexStart":"","half":"","borderRight":""}},[_c('ui-h',{attrs:{"color":"black"}},[_vm._v(" Register a new account ")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v(" Your password must be at least 8 characters and one uppercase character and one number/special character. ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onRegister)}}},[_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Email Address")]),_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerEmail),expression:"registerEmail"}],staticClass:"form-input",attrs:{"type":"text"},domProps:{"value":(_vm.registerEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.registerEmail=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"name":"Password","vid":"password","rules":{
                            required: true,
                            regex: /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerPassword),expression:"registerPassword"}],staticClass:"form-input",attrs:{"type":"password"},domProps:{"value":(_vm.registerPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.registerPassword=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-wrapper"},[_c('label',[_vm._v("Confirm Password")]),_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registerConfirmPassword),expression:"registerConfirmPassword"}],staticClass:"form-input",attrs:{"type":"password"},domProps:{"value":(_vm.registerConfirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.registerConfirmPassword=$event.target.value}}}),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('ui-p',[_c('ui-button',{attrs:{"fullMobile":"","primary":"","loading":_vm.isRegistering}},[_vm._v("Create account ")])],1)],1)]}}])}),_c('modal',{attrs:{"height":"auto","scrollable":"","adaptive":"","name":"register"}},[_c('div',{staticClass:"modal"},[_c('ui-h',{attrs:{"color":"black"}},[_vm._v(" Registration complete ")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v(" We've sent an email to "+_vm._s(this.registerEmail)+" with a link that you will need to click/tap to verifiy your account. Once you have done that you can then login to your account. ")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v(" NOTE: If you don't see the email please check your junk/spam folder. ")]),_c('ui-p',[_c('ui-button',{attrs:{"secondary":""},on:{"tapped":function($event){return _vm.$modal.hide('register')}}},[_vm._v("Close")])],1)],1)]),_c('modal',{attrs:{"height":"auto","scrollable":"","adaptive":"","name":"verify-email"}},[_c('div',{staticClass:"modal"},[_c('ui-h',{attrs:{"color":"black"}},[_vm._v(" Your account has not been verified ")]),_c('ui-p',{attrs:{"color":"black"}},[_vm._v(" When you registered an account you would have received an email to verify your account. If you need to verify your account simply close this window and reset your password. ")]),_c('ui-p',[_c('ui-button',{attrs:{"secondary":""},on:{"tapped":function($event){return _vm.$modal.hide('verify-email')}}},[_vm._v("Close")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }